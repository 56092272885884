
import { Link } from 'react-router-dom';
import { Row, Col, Nav } from 'react-bootstrap';

const NavLink = ({ to, children }) => <Nav.Item>
  <Link to={to} className='nav-item'>
    <div className='nav-link'>{children}</div>
  </Link>
</Nav.Item>;

export default ({ path, children }) => <>
  <Row style={{ width: '100%'}}>
    <Col sm="2" className="sidebar">
      <Nav className="d-none d-md-block bg-light fluid"  style={{ minHeight: 'calc(100vh - 58px)'}}>
        <NavLink to="/">
          Pages
        </NavLink>
        <NavLink to="/charts">
          Charts
        </NavLink>
      </Nav>
    </Col>
    <Col sm="10">
      {children}
    </Col>
  </Row>
</>