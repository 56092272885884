import React, { Component, forwardRef } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ListGroup, Card, Container, Row } from 'react-bootstrap';
import getSlug from 'speakingurl';

import {
  DataTable,
  CreateButton,
  DeleteConfirmButton,
  CreateEditModal as CreateModal,
  CreateEditModal as EditModal,
  SmallSpinner,
} from '../shared/ReactToolbox';
import { mapPagesToProps } from '../../redux/reducers/Pages';
import pageActions from '../../redux/actions/Pages';
import PageTypesDropdown from './PageTypesDropdown';
import PageStatusDropdown from './PageStatusDropdown';
import PageThumbnailModalButton from './PageThumbnailModalButton';
import { pageStatus } from './Page';

class PagesList extends Component {
  state = {
    showNewModal: false,
    propsArray: [],
    droppedItem: null,
  }

  componentDidMount() {
    this.props.getAllPages({ params: { type: this.props.type } });
  }

  render() {
    const {
      getPagesIsLoading,
      pagesList,
      createPage,
      createPageIsLoading,
      updatePage,
      updatePageIsLoading,
      movePageIsLoading,
      deletePage,
      deletePageIsLoading,
      type,
     } = this.props;
    if (getPagesIsLoading || !pagesList) return <SmallSpinner />;
    
    const formFields = {
      title: {
        label: 'Title',
        onChange: (title, { title: prevTitle, slug: prevSlug }) => ({
          title,
          slug: prevSlug === getSlug(prevTitle) ? getSlug(title) : prevSlug,
        }),
      },
      description: {
        label: 'Description',
        formProps: { as: 'textarea' }
      },
      slug: { label: 'Slug' },
      type: {
        label: 'Page type',
        component: PageTypesDropdown,
      },
      status: {
        label: 'Status',
        component: PageStatusDropdown,
      },
    };

    return <>
      <Container style={{ marginTop: '50px' }}>
        <DataTable
          onClickRow={pageInEditModal => this.setState({ pageInEditModal })}
          showHeader={false}
          rowsPerPage={null}
          filterColumn='title'
          columns={[
            {
              orderBy: 'title',
              name: 'Title',
              selector: ({ slug, title }) => 
                <Link to={`pages/${type === null ? '' : `${type}/`}${slug}`}>{title}</Link>,
            },
            {
              name: 'Status',
              orderBy: ({ status }) => status,
              selector: ({ status }) => pageStatus[status],
            },
            {
              name: 'Actions',
              selector: page => <>
                <DeleteConfirmButton
                  modalTitle="Delete page"
                  onDelete={() => deletePage(page)}
                  loading={deletePageIsLoading}
                  className="float-end"
                />
              </>
            },
          ]}
          data={Object.values(pagesList).sort(({ order: a }, { order: b }) => a > b)}
          onMove={({ item, target, reset }) => {
            this.props.movePage(
              item,
              {
                args: { 
                  target,
                  position: item.order > target.order ? 'right' : 'left',
                },
                callback: reset,
                onError: reset,
              }
            );
          }}
          orderByDefault='order'
        />
        
        <CreateButton onClick={() => this.setState({ showNewModal: true })} />
      </Container>

      {this.state.pageInEditModal &&
        <EditModal
          show={!!this.state.pageInEditModal}
          modalTitle="Modify page"
          loading={updatePageIsLoading}
          onHide={() => this.setState({ pageInEditModal: null })}
          initialState={this.state.pageInEditModal}
          formFields={{
            ...formFields,
            thumbnail: {
              label: 'Thumbnail',
              component: PageThumbnailModalButton,
            },
          }}

          onSave={page => updatePage(
            page,
            { original: this.state.pageInEditModal, callback: () => this.setState({ pageInEditModal: null }) }
          )}
      />}
      
      <CreateModal
        show={this.state.showNewModal}
        modalTitle="New page"
        loading={createPageIsLoading}
        onHide={() => this.setState({ showNewModal: false })}
        initialState={{ title: '', slug: '', type: this.props.type, status: 'draft' }}
        includeData={{ charts: [], tags: [], chart_indexes: [], thumbnail: '' }}
        formFields={formFields}

        onSave={newPage => createPage(
          newPage,
          { callback: () => this.setState({ showNewModal: false }) }
        )}
      />
    </>;
  }
}

export default connect(mapPagesToProps, {
  ...pageActions,
})(PagesList);