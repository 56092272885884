import { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import PagesList from './PagesList';
import { pageTypes } from './Page';

export default () => {
  const [type, setType] = useState('analysis');
  return <>
    <Tabs
      activeKey={type}
      onSelect={(type) => setType(type)}
      className="mb-3"
    >
    {Object.keys(pageTypes).map((type, key) =>
      <Tab eventKey={type} title={pageTypes[type]} key={key}>
        <PagesList type={type === 'null' ? null : type} />
      </Tab>
    )}
    </Tabs>
  </>;
}