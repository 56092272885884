import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ListGroup, Card, Container, Row } from 'react-bootstrap';
import getSlug from 'speakingurl';

import {
  DataTable,
  CreateEditModal as CreateModal,
  CreateEditModal as EditModal,
  LoadingIndicator,
  CreateButton,
  DeleteConfirmButton,
} from '../shared/ReactToolbox';
import { mapChartsToProps } from '../../redux/reducers/Charts';
import chartActions from '../../redux/actions/Charts';

class ChartsList extends Component {
  state = {
    showNewModal: false,
  }

  componentDidMount() {
    this.props.getChartsList();
  }

  render() {
    const { getChartsIsLoading, chartsList, createChart, createChartIsLoading, updateChart, updateChartIsLoading, deleteChart, deleteChartIsLoading } = this.props;
    if (getChartsIsLoading || !chartsList) return <LoadingIndicator />;
    
    const formFields = {
      name: {
        label: 'Name',
        onChange: (name, { name: prevName, slug: prevSlug }) => ({
          name,
          slug: prevSlug === getSlug(prevName) ? getSlug(name) : prevSlug,
        }),
      },
      slug: { label: 'Slug' },
    };

    return <>
      <Container style={{ marginTop: '50px' }}>
        
      <DataTable
          onClickRow={chartInEditModal => this.setState({ chartInEditModal })}
          showHeader={false}
          rowsPerPage={null}
          filterColumn='name'
          columns={[
            {
              orderBy: 'name',
              name: 'Name',
              selector: ({ slug, name }) => 
                <Link to={`${slug}`}>{name}</Link>
            },
            {
              name: 'Actions',
              selector: chart => <>
                <DeleteConfirmButton
                  modalTitle="Delete chart"
                  onDelete={() => deleteChart(chart)}
                  loading={deleteChartIsLoading}
                  className="float-end"
                />
              </>
            },
          ]}
          data={Object.values(chartsList)}
          orderByDefault='name'
        />
      </Container>

      {this.state.chartInEditModal &&
        <EditModal
          show={!!this.state.chartInEditModal}
          modalTitle="Modify chart"
          loading={updateChartIsLoading}
          onHide={() => this.setState({ chartInEditModal: null })}
          initialState={this.state.chartInEditModal}
          formFields={formFields}

          onSave={chart => this.props.updateChart(
            chart,
            {
              original:this.state.chartInEditModal,
              callback: () => this.setState({ chartInEditModal: null }),
            }
          )}
      />}
      
      <CreateModal
        show={this.state.showNewModal}
        modalTitle="New chart"
        loading={createChartIsLoading}
        onHide={() => this.setState({ showNewModal: false })}
        initialState={{ name: '', slug: '' }}
        formFields={formFields}

        onSave={newChart => this.props.createChart(
          newChart,
          { callback: () => this.setState({ showNewModal: false }) }
        )}
      />
    </>;
  }
}

export default connect(mapChartsToProps, {
  ...chartActions,
})(ChartsList);