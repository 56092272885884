
import { Row, Col, Container } from 'react-bootstrap';

export const ErrorPage = ({ children }) =>
  <Container>
    <Row className="error-page">
      <Col>
        <div className="jumbotron">
          {children}
        </div>
      </Col>
    </Row>
  </Container>;
