import { useState } from 'react';
import RichTextEditor from '../slate/RichTextEditor'
import { Container, Spinner, Badge } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { DashboardTitle } from '../dashboard/Dashboard';
import { SmallSpinner } from '../shared/ReactToolbox';
import { mapPagesToProps } from '../../redux/reducers/Pages';
import { mapChartsToProps } from '../../redux/reducers/Charts';
import pageActions from '../../redux/actions/Pages';
import { ErrorPage } from '../shared/Errors';

export const pageTypes = {
  analysis: 'Analysis',
  null: 'Site',
};

export const pageStatus = {
  draft: 'Draft',
  published: 'Published',
  deleted: 'Deleted',
};

export default connect(
  (state, ownProps) => ({
    ...mapPagesToProps(state, ownProps),
    ...mapChartsToProps(state, ownProps),
  }),
  pageActions
)(
  ({ updatePageIsLoading, getAllPagesIsLoading, updatePage, page, chartsListIsLoading, chartsList, ...r }) => {
    if (getAllPagesIsLoading || chartsListIsLoading) return <SmallSpinner />;
    if (page === null) {
      return <ErrorPage>Page not found</ErrorPage>;
    }

    return page && <>
      <DashboardTitle>
        {updatePageIsLoading &&
          <Spinner
            animation="border"
            role="status"
            size="sm"
            className="float-end"
            style={{ marginLeft: '10px' }}
          />}
        {page && page.title}
      </DashboardTitle>
      <Container style={{ marginTop: '20px' }}>
        <h3>
          {chartsList && page.charts.map((slug, index) =>
            <Badge bg="light" className="chart" key={index}>
              <Link to={`/charts/${slug}`}>
                {chartsList[slug] ? chartsList[slug].name : <i>Not found: {slug}</i>}
              </Link>
            </Badge>
          )}
        </h3>
        <RichTextEditor
          initialValue={page.content}
          onSave={content => updatePage({ 
            ...page,
            content,
            charts: content
              .filter(({ type }) => type === 'plotly-chart')
              .map(({ slug }) => slug),
            chart_indexes: content
              .filter(({ type, slug }) => type === 'plotly-chart' && chartsList[slug])
              .map(({ slug, index }) => [slug, index]),
          })}
          loading={updatePageIsLoading}
        />
      </Container>
    </> || null;
  }
);