import { DropdownButton, Dropdown, ButtonGroup } from 'react-bootstrap';

import { pageTypes } from './Page';

const PageTypesDropdown = ({ onChange, value }) => {
  return (
    <ButtonGroup className="form-control form-control-lg">
      <DropdownButton
        id="dropdown"
        title={pageTypes[value || null]}
        onClick={e => e.stopPropagation()}
        size="sm"
      >
        {Object.entries(pageTypes)
          .map(([type, name], key) =>
            <Dropdown.Item
              href="#"
              key={key}
              onClick={(e) => onChange(type === 'null' ? null : type)}
            >
              {name}
            </Dropdown.Item>
          )
        }
      </DropdownButton>
    </ButtonGroup>
  );
}

export default PageTypesDropdown;
