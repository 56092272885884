import { Transforms } from 'slate'
import { ReactEditor, useSlateStatic, useSlate, useSelected } from 'slate-react'
import { Editor, Range, Element as SlateElement } from 'slate'
import { css } from '@emotion/css'
import { Link } from 'react-router-dom'

import { Button, Icon } from './Components'


// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
const InlineChromiumBugfix = () => (
  <span
    contentEditable={false}
    className={css`
      font-size: 0;
    `}
  >
    ${String.fromCodePoint(160) /* Non-breaking space */}
  </span>
)
  
export const LinkElement = ({ attributes, children, element: { url } }) => {
  const selected = useSelected()
  const className = selected ? css`box-shadow: 0 0 0 3px #ddd` : css`cursor: pointer;`;
  const editor = useSlateStatic()
  const readOnly = ReactEditor.isReadOnly(editor)
  return (
    url.startsWith('http') || !readOnly
      ? <a
          {...attributes}
          href={url.startsWith('http') ? url : `/pages${url}`}
          target="_blank"
          rel="noreferrer"
          className={className}
        >
          <InlineChromiumBugfix />
            {children}
          <InlineChromiumBugfix />
        </a>
      : <Link
          {...attributes}
          to={url}
          className={className}
        >
          <InlineChromiumBugfix />
            {children}
          <InlineChromiumBugfix />
        </Link>
  )
}

export const ToggleLinkButton = () => {
  const editor = useSlate()
  const active = isLinkActive(editor);
  return (
    <Button
      active={active}
      onMouseDown={e => {
        e.preventDefault()
        if (active) {
          unwrapLink(editor)
        } else {
          const url = window.prompt('Enter the URL of the link:')
          if (!url) return
          insertLink(editor, url)
        }
      }}
    >
      <Icon>{active ? 'link_off' : 'link'}</Icon>
    </Button>
  )
}

const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url)
  }
}

const isLinkActive = editor => {
  const [link] = Editor.nodes(editor, {
    match: n =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  })
  return !!link
}

const unwrapLink = editor => {
  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  })
}

const wrapLink = (editor, url) => {//: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const link = {//: LinkElement = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}


export const withInlines = editor => {
  const { insertData, insertText, isInline } = editor

  editor.isInline = element => element.type === 'link' || isInline(element)

  // This code just seems to break
  // editor.insertText = text => {
  //   if (text) { // && isUrl(text)) {
  //     wrapLink(editor, text)
  //   } else {
  //     insertText(text)
  //   }
  // }

  // editor.insertData = data => {
  //   const text = data.getData('text/plain')

  //   if (text) { //  && isUrl(text)) {
  //     wrapLink(editor, text)
  //   } else {
  //     insertData(data)
  //   }
  // }

  return editor
}