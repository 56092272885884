import { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { css } from '@emotion/css'

import { mapChartsToProps } from '../../redux/reducers/Charts';
import { getBaseUrl } from '../../utils/Utils';

const thumbnailUrl = ({ slug, index }) => `${slug}.${index}.png`;

export default connect(mapChartsToProps)(
  ({ onChange, value, state: { chart_indexes: chartIndexes }, ...restProps }) => {
    const [modalActive, setModalActive] = useState(false);
    const hideModal = () => setModalActive(false);

    return <>
      <span onClick={() => setModalActive(true)}>
        {value
          ? <>
              <br />
              <img
                src={`${getBaseUrl()}/static/${value}`}
                className={css`
                  width: 100%;
                  max-height: 580px; 
                  max-width: 450px; 
                `}
              />
            </>
          : <div className="form-control form-control-lg"><i>Choose a thumbnail</i></div>
        }   
    </span>
    
    {modalActive &&
      <Modal
          show
          onHide={hideModal}
          onClick={e => e.stopPropagation()}
          centered
          dialogClassName="mw-100 w-75"
      >
          <Modal.Header closeButton>
              <Modal.Title>Choose thumbnail</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {chartIndexes.map(([ slug, index ], key) =>
              <img
                key={key}
                src={`${getBaseUrl()}/static/${thumbnailUrl({ slug, index})}`}
                onClick={() => {
                  onChange(thumbnailUrl({ slug, index }));
                  hideModal();
                }}
                className={css`
                  width: 100%;
                  max-height: 580px; 
                  max-width: 450px; 
                `}
              />
            )}
          </Modal.Body>

          <Modal.Footer>
              <Button
                  variant="secondary"
                  onClick={hideModal}
              >
                Cancel
              </Button>
          </Modal.Footer>
      </Modal>
    }
  </>;
  });