import React from 'react';
import Root from './Root';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Login from './components/login/Login';

import { LocalizationProvider } from './components/shared/ReactToolbox';
import Dashboard from './components/dashboard/Dashboard';
import Sidebar from './components/dashboard/Sidebar';
import PagesTabs from './components/pages/PagesTabs';
import Page from './components/pages/Page';
import ChartsList from './components/charts/ChartsList';
import Chart from './components/charts/Chart';
import AuthenticatedRoute from './components/login/AuthenticatedRoute';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
// import { TouchBackend } from 'react-dnd-touch-backend'
// <DndProvider  backend={TouchBackend} options={{ enableTouchEvents: false, enableMouseEvents: true }}>
const App = () => {
  return (
    <div className="main">
      <DndProvider backend={HTML5Backend}>
        <LocalizationProvider>
          <Root>
            <ToastContainer hideProgressBar={true} newestOnTop={true} />
              <BrowserRouter>
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route
                    exact
                    path="/"
                    element={<AuthenticatedRoute component={Dashboard} />}
                  >
                    {/* <Route
                      path=""
                      element={<>
                        <Sidebar path="dashboard">
                          <Dashboard />
                        </Sidebar>
                      </>}
                    /> */}
                    <Route
                      path=""
                      element={<>
                        <Sidebar path="pages">
                          <PagesTabs />
                        </Sidebar>
                      </>}
                    />
                    <Route
                      path="charts"
                      element={<>
                        <Sidebar path="charts">
                          <ChartsList />
                        </Sidebar>
                      </>}
                    />
                    <Route
                      path="charts/:slug"
                      element={<AuthenticatedRoute component={Chart} />}
                    />
                    <Route
                      path="pages/:slug"
                      element={
                        <Sidebar path="pages">
                          <AuthenticatedRoute component={Page} type={null} />
                        </Sidebar>}
                    />
                    <Route
                      path="pages/:type/:slug"
                      element={
                        <Sidebar path="pages">
                          <AuthenticatedRoute component={Page} />
                        </Sidebar>}
                    />
                  </Route>
                </Routes>
              </BrowserRouter>
          </Root>
        </LocalizationProvider>
      </DndProvider>
    </div>
  );
}

export default App;