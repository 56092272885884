import React, { useState, useCallback, useMemo } from "react";
import PropTypes from 'prop-types';
import isHotkey from 'is-hotkey'
import { Slate, Editable, withReact, useSlate } from 'slate-react'
import { Editor, Transforms, createEditor, Element as SlateElement } from 'slate'
import { withHistory } from 'slate-history'

import { Element, Leaf } from './Element'
import { Button, Icon, Toolbar } from './Components'
import { ChartElement, InsertChartButton, withChart } from './Chart'
import { ImageElement, InsertImageButton, withImage } from './Image'
import { LinkElement, ToggleLinkButton, withInlines } from './Link'

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
  'mod+s': 'save',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']

const RichTextEditor = ({ initialValue, onSave, readOnly }) => {
  const [value, setValue] = useState(
    initialValue || [
      {
        type: 'paragraph',
        children: [
          { text: '' },
        ],
      },
    ]
  )
  const renderElement = useCallback(props =>
    <Element
      {...props}
      customElements={{
        image: ImageElement,
        'plotly-chart': ChartElement,
        link: LinkElement,
      }} 
    />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const editor = useMemo(() => withHistory(withInlines(withChart(withReact(createEditor())))), [])

  if (!value) return null;
  return (
    <Slate
      editor={editor}
      value={value}
      onChange={value => setValue(value)}
      className="slate-editor-margin"
    >
      <Toolbar>
        <MarkButton format="bold" icon="format_bold" />
        <MarkButton format="italic" icon="format_italic" />
        <MarkButton format="underline" icon="format_underlined" />
        <MarkButton format="code" icon="code" />
        <MarkButton format="align-center" icon="format_align_center" />
        <BlockButton format="heading-one" icon="looks_one" />
        <BlockButton format="heading-two" icon="looks_two" />
        <BlockButton format="heading-three" icon="looks_3" />
        <BlockButton format="heading-four" icon="looks_4" />
        <BlockButton format="heading-five" icon="looks_5" />
        <BlockButton format="block-quote" icon="format_quote" />
        <BlockButton format="numbered-list" icon="format_list_numbered" />
        <BlockButton format="bulleted-list" icon="format_list_bulleted" />
        <ToggleLinkButton />
        <InsertImageButton />
        <InsertChartButton />
      </Toolbar>
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        placeholder="Enter some rich text…"
        spellCheck
        autoFocus
        readOnly={readOnly}
        onKeyDown={event => {
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
              event.preventDefault()
              const mark = HOTKEYS[hotkey]
              if (mark === 'save') {
                onSave(value)
              } else {
                toggleMark(editor, mark)
              }
            }
          }
        }}
      />
    </Slate>
  )
}
RichTextEditor.propTypes = {
  onSave: PropTypes.func.isRequired,
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type),
    split: true,
  })
  const newProperties = {//: Partial<SlateElement> = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  }
  Transforms.setNodes(editor, newProperties) //<SlateElement>(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor, format) => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    })
  )

  return !!match
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const BlockButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

const MarkButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

export default RichTextEditor