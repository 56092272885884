import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

// https://dev.to/iamandrewluca/private-route-in-react-router-v6-lg5
const AuthenticatedRoute = ({ component: Component, isAuthenticated, dispatch, ...restProps }) => {
  const params = useParams();

  return isAuthenticated ? <Component {...params} {...restProps} /> : <Navigate to="/login" />;
}
const mapStateToProps = state => ({ isAuthenticated: state.auth.isAuthenticated });
  
export default connect(mapStateToProps)(AuthenticatedRoute);
