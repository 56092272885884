import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Outlet } from 'react-router-dom';
import { Row, Col, Navbar, Nav, Tabs, Tab } from 'react-bootstrap';
import _ from 'lodash';

import chartsActions from '../../redux/actions/Charts';
import pagesActions from '../../redux/actions/Pages';
import tagsActions from '../../redux/actions/Tags';
import { mapChartsToProps } from '../../redux/reducers/Charts';
import { mapPagesToProps } from '../../redux/reducers/Pages';
import { mapTagsToProps } from '../../redux/reducers/Tags';
import { logout } from '../../redux/actions/Login';

const Dashboard = ({ auth, logout, getChartsList, getAllPages, getTagsList }) => {
  useEffect(() => {
    getChartsList();
    getAllPages();
    getTagsList();
  }, [])
  const { user } = auth;
  
  return (
    <>
      <Navbar bg='light'>
        <Link to="/">
          <Navbar.Brand>Dashboard</Navbar.Brand>
        </Link>
        <Nav className="mr-auto">
          <Navbar.Text id="dashboard-title">
            <div ></div>
          </Navbar.Text>
        </Nav>
        <Navbar.Collapse className='justify-content-end'>
          <Navbar.Text>
            User: <b>{user.username}</b>
          </Navbar.Text>
          <Nav.Link onClick={() => logout()}>Logout</Nav.Link>
        </Navbar.Collapse>
      </Navbar>
      <Outlet />
    </>
  );
}
Dashboard.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

export const DashboardTitle = ({ children }) => createPortal(children, document.getElementById('dashboard-title'));

const mapStateToProps = (state, ownState) => ({
  auth: state.auth,
  ...mapChartsToProps(state, ownState),
  ...mapPagesToProps(state, ownState),
  ...mapTagsToProps(state, ownState),
});

export default connect(mapStateToProps, {
  logout,
  ...chartsActions,
  ...pagesActions,
  ...tagsActions,
})(Dashboard);
