import axios from '../utils/Axios';
import { toastOnError } from '../utils/Utils';
import reduxCrudFactory from '../utils/ReduxCrudFactory';

export default reduxCrudFactory({
  axios,
  id: 'slug',
  onError: toastOnError,
  actions: {
    getList: true,
    get: true,
    create: true,
    update: true,
    delete: true,
  },
  config: {
    charts: {
      route: '/api/analysis/charts/',
    },
    pages: {
      route: '/api/analysis/pages/',
      parent: 'type',
      parentId: 'slug',
      actions: {
        getAll: true,
      },
      includeActions: {
        movePage: {
          isAsync: true,
          route: page => `/api/analysis/pages/${page.slug}/move/`,
          method: 'put',
          prepare: (page, { args: { target, position } }) => ({ target, position }),
          onResponse: (pages, { setAllPages }) => setAllPages(pages),
        },
      },
    },
    tags: {
      route: '/api/analysis/tags/',
    },
  },
});
