import { DropdownButton, Dropdown, ButtonGroup } from 'react-bootstrap';

import { pageStatus } from './Page';

const PageStatusDropdown = ({ onChange, value }) => {
  return (
    <ButtonGroup className="form-control form-control-lg">
      <DropdownButton
        id="dropdown"
        title={pageStatus[value]}
        onClick={e => e.stopPropagation()}
        size="sm"
      >
        {Object.entries(pageStatus)
          .map(([status, name], key) =>
            <Dropdown.Item
              href="#"
              key={key}
              onClick={(e) => onChange(status)}
            >
              {name}
            </Dropdown.Item>
          )
        }
      </DropdownButton>
    </ButtonGroup>
  );
}

export default PageStatusDropdown;
